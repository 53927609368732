import React from 'react'
import { Button } from "react-bootstrap";

const OrderButton = () => {
  return (
    <>
    <Button  onClick={(e) => {
            
            e.preventDefault();
    
                window.location.href='https://webordering.rmwservices.com/v19.0.WebUI/restaurants.aspx?wci=tribe'
            
            }}>Order Online</Button>

    </>
  )
}

export default OrderButton