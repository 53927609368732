import React from 'react';

const BlogPost = ({ post }) => {
  return (
    <div>
      <h3>{post.title}</h3>
      <p>{post.content}</p>
      {/* Add a "Read More" link to navigate to the post detail page */}
      <a href={`/post/${post.id}`}>Read More</a>
    </div>
  );
};

export default BlogPost;