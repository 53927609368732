import React from 'react'
import { Button } from "react-bootstrap";
import './BookOnLine.css'
import Logo from './../assets/Logos/TribeLogo.jpg'
const BookOnLine = () => {
  return (

    <>
       <div className='placement'>
      <div className='image-container-home'>
       <img src={Logo} className='center responsive-image-home' alt="tribe logo"/>
       </div>

      <Button  onClick={(e) => {
            
            e.preventDefault();
    
                window.location.href='https://webordering.rmwservices.com/v19.0.WebUI/restaurants.aspx?wci=tribe'
            
            }}>Order Online</Button>

        </div> 
    
    </>
  )
}

export default BookOnLine