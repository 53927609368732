// import React, { useState } from 'react';
// import ReactMapGL from 'react-map-gl';

// const mapKey = "pk.eyJ1Ijoiam9uZXNiYSIsImEiOiJja3prYzNrdHUwNjl2MnZtbjQ2NjIxZWR1In0.z7MCqi5H_1TP0YV5r2DPWw";

// const Map = () => {

//     const [viewport, setViewport] = useState({

//         width: '90%',
//         height: '90%',
//         latitude: 37.7749, // Specify the latitude of the map center
//         longitude: -122.4194, // Specify the longitude of the map center
//         zoom: 5 // Specify the zoom level
        
//       });


//   return (
   


//      <ReactMapGL
//       {...viewport}
//       mapboxAccessToken={mapKey} // Set your Mapbox API token
//       onViewportChange={(newViewport) => setViewport(newViewport)}
      


      
//     />

      

//   )
// }

// export default Map


import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Map = () => {
  const mapStyles = {
    height: '40vh',
    width: '100%',
  };

   
  const defaultCenter = {
    lat: 53.655311600169775, // Specify the latitude of the map center
    lng: -6.4185786944874454, // Specify the longitude of the map center
  };



  const mapKey = "AIzaSyCTOyQ7dEj_o_tdrLsYq69Fl2U92h6Ccs8";
  return (
    <LoadScript
      googleMapsApiKey={mapKey}
    >
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={17} // Specify the zoom level
        center={defaultCenter}
      >
        {/* Example Marker */}
        <Marker position={defaultCenter} />
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;