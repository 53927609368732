import {useEffect, useState} from 'react'
import './BackGroundSlider.css'
import imageSlide from './data'
import BookOnLine from './BookOnLine'

const BackGroundSlider = () => {

    const [currentState,setcurrentState] = useState(0)

    useEffect(() =>{

      const timer = setTimeout(() =>{

        if(currentState===19){

          setcurrentState(0)
        }
        else{
        setcurrentState(currentState+ 1)

        }
      },1700)

      return () => clearTimeout(timer)

    },[currentState])

    const bgImageStyle = {

        backgroundImage: `url(/${imageSlide[currentState].url})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '95%'
    }

    const goToNext = () =>{

      setcurrentState(currentState)

    }
  return (
    <>
    
    {/* <img src="/One.jpg" /> */}
    <div className='container-style'>
    
        <div style={bgImageStyle}></div>

        <div className='transparent-background'>

        
        <BookOnLine />
        
        </div>
        
        <div className='description'>
        
          <div className='carousel-boullt'>
          {

            	imageSlide.map((imageSlide,currentState)=>{

              <span key={currentState} onClick={()=>goToNext(currentState)}>1</span>

          })
          }
          
        </div>
    
        </div>



    </div>
    </>
  )
}

export default BackGroundSlider