
import Navbar from './navbar'
import React, { useState } from 'react';
import {  BrowserRouter as Router, Route, useLocation, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Location from './pages/Location';

import About from './pages/About';


import Menus from './pages/menus/menus'


import './App.css';

import BlogList from './components/blog/BlogList';
import PostDetail from './components/blog/PostDetail';


const propsStarter = {
  data: 'Mains',
  image: '/menus/starters1.jpg'
};

const propsJunior = {
  data: 'Juniors',
  image: '/menus/TribeSepJuniors-1.png'
};

const propsTapas = {
  data: 'Tapas',
  image: '/menus/tapasthurs1.jpg'
};

const starter = {...propsStarter};
const juniors = {...propsJunior};
const tapa = {...propsTapas}


function App() {

  const [posts] = useState([
    { id: 1, title: 'First Post', content: 'Content of the first post.', date: '2022-01-30' },
    { id: 2, title: 'Second Post', content: 'Content of the second post.', date: '2022-01-31' },
    // Add more posts as needed
  ]);
  console.log(posts)

  return (

<>


<Router>
<Navbar/>
<Routes>
  <Route exact path="/" element={<Home/>} />
  <Route path="/about" element={<About/>} />
  <Route path="/location" element={<Location/>} />
  <Route path="/starter" element={<Menus image={starter}/>} />
  <Route path="/juniors" element={<Menus image={juniors}/>} />
  <Route path="/tapa" element={<Menus image={tapa}/>} />
  <Route path="/voucher" element={() =>{

        window.location.href = 'https://tribe.voucherconnect.com/product/4786';
    return null


  }} />
  <Route path="/blog" exact element={<BlogList posts={posts} />} />
  <Route path="/post/:id" render={({ match }) => {
        const postId = parseInt(match.params.id, 10);
        const post = posts.find(post => post.id === postId);
        return <PostDetail post={post} />;
      }} />
</Routes>

</Router>


</>
 
  );
}

export default App;
