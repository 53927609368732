const imageSlide = [
    {

        url: 'One.jpg',
        title: ' one title',
        body: 'one body'

    },
    {

        url: 'Two.jpg',
        title: ' two title',
        body: 'two body'

    },
    {

        url: 'Three.jpg',
        title: ' three title',
        body: 'three body'

    }
    ,
    {

        url: '0a34aee4-923e-424b-bb16-9747d60ad8ee.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: '8b310222-ee24-4a5f-948f-eaf8008a13fb.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: '15b4f42a-e371-4ee1-9594-04d615485ad0.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: '33a72fd2-9117-476f-86de-15c4729f8d45.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: '574ed27e-932d-49e2-ba43-c0856c395aaa.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: '758bd8fb-3ca9-4e4a-8377-8efc8f363990.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: '46204b1c-8dc8-41c7-8673-ef5bbb82b16f.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: '88078eb6-be18-4be0-879e-2f75e26d2cef.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: '692421e7-b65a-43f7-85b0-c5823b5789a2.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: 'a461cdce-fc46-4aa3-a6d1-19a5755909b4.jpg',
        title: ' three title',
        body: 'three body'

    }
    ,
    {

        url: 'a1291619-039c-41c2-8a4b-78f2bee0a80f.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: 'ac03d68f-0cbd-42a3-805d-a02da42ceb9a.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: 'b4771994-61eb-4ff8-9faf-a3af94cca99a.jpg',
        title: ' three title',
        body: 'three body'

    }
    ,
    {

        url: 'd78abf8a-92f5-43cd-a5be-6973e2ba4fe4.jpg',
        title: ' three title',
        body: 'three body'

    }
    ,
    {

        url: 'd41165de-b127-43bd-aadd-46ab763b11f3.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: 'fa2e8ad5-d8c4-43fa-956c-fa28bd5fc291.jpg',
        title: ' three title',
        body: 'three body'

    },
    {

        url: 'fc60d99a-9b43-445a-a4e2-72ad94375665.jpg',
        title: ' three title',
        body: 'three body'

    }

]

export default imageSlide