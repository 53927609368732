import React from 'react'
import './OpeningHours.css'

const OpeningHours = () => {
  return (
    <>
    
    <div className='grid-title'>

    <h3>Opening Hours</h3>

    </div>


    <div className="grid-container">
      <div className="column">
        <div className="row-container">
        <div className="row">

        <b>Monday</b>
        <p>3PM - 9PM</p>


        </div>
        <div className="row">

            <b>Saturday</b>
            <p>4PM - 9PM</p>


            </div>

        </div>
   
      </div>
      <div className="column">
        {/* Content for the second column */}
        <b>Tuesday - Wednesday</b>
        <p>Closed</p>
      </div>

      <div className="column">
      <div className="row-container">
        <div className="row">

        <b>Thursday - Friday</b>
        <p>5PM - 9PM</p>


        </div>
        <div className="row">

            <b>Sunday</b>
            <p>2PM - 8PM</p>


            </div>

        </div>
      </div>
      
    </div>

    </>
  )
}

export default OpeningHours