import React from 'react'
import Map from './Map';
//import './mapbox.css'
import './Location.css'

const Location = () => {
  return (
    <>
    {/* <div className='center '>
    <h4>Our location</h4>
    <Map/>
    </div> */}

<div className="page-container">
      <div className="centered-div">
        {/* Content for the centered div */}
        <h3>Location</h3>
        <Map/>
      </div>
    </div>

    </>
  )
}

export default Location