import './Home.css'
import BookOnLine from './BookOnLine';
import BackGroundSlider from '../pages/BackGroundSlider'
import HomeInfo from './HomeInfo';
import OpeningHours from './OpeningHours';
import Footer from './footer';
import PhotoGallery from '../components/PhotoGallery';
import Location from './Location';
import OrderButton from './OrderButton';

const images = [
  'https://example.com/image1.jpg',
  'https://example.com/image2.jpg',
  'https://example.com/image3.jpg',
  // Add more image URLs as needed
];

const Home = () => {
  return (
    <>
    
    <div className='Slide'>

    
    <BackGroundSlider/>
    
    <HomeInfo/>

    <OpeningHours />

    {/* <PhotoGallery images={images}/> */}

    <Location/>

    <OrderButton/>

    <Footer />

    </div>

    </>
  )
}

export default Home