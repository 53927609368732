import React from 'react'
import './HomeInfo.css'

const HomeInfo = () => {
  return (
   
   
   <>
   
   <h3>Home</h3>

   <div className="container">
      <div className="column1">
        {/* Content for the first column */}
        <h2>SIGNATURE DISHES</h2>
        <p>We serve up a ‘no-rules’ menu, where you can mix ‘n’ match small bites with larger plates. Dig into juicy wings, garlicky foccacia or fresh seafood – and don’t forget to share! Our signature sourdough pizza is made with 48-hour leavened dough and woodfired for just over 2 minutes for a perfectly light and crispy crust.</p>
      </div>
      <div className="column2">
        {/* Content for the second column */}
        <h2>SUSTAINABLE PRODUCE</h2>
        <p>We pride ourselves on a seasonal menu made up of the very best produce from surrounding producers in Meath and Louth. We also grow our own herbs, vegetables and fruit and use these wherever possible. For bookings of more than 6 guests please email info@tribeduleek.ie or phone us on 041-9865060</p>
      </div>
    </div>
    </>
  )
}

export default HomeInfo