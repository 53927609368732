// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMobileMenu = () => setMenuOpen(false);


    const redirectToVoucher = () =>{

      window.open(
        'https://tribe.voucherconnect.com/product/4786',
        '_blank' // <- This is what makes it open in a new window.
      );

    }
  return (

    <nav className="navbar" >
      <Link className="logo" to="/" onClick={closeMobileMenu}>
        Tribe
      </Link>
      <div className={`nav-items ${isMenuOpen ? 'open' : ''}`}>
        <Link className="nav-link" to="/" onClick={closeMobileMenu}>
          Home
        </Link>
        <div className="dropdown">
        <Link className="nav-link" to="#">
          Menus
        </Link>
          <div className="dropdown-content">
            <Link to="/starter" onClick={closeMobileMenu}>Main</Link>
            <Link to="/juniors" onClick={closeMobileMenu}>Kids</Link>
            <Link to="/tapa" onClick={closeMobileMenu}>Tapa Thursday</Link>
          </div>
        </div>
        <Link className="nav-link" to="/about" onClick={closeMobileMenu}>
          About
        </Link>
        <Link className="nav-link" to="/location" onClick={closeMobileMenu}>
          Location
        </Link>
        
        <Link className="nav-link" onClick={redirectToVoucher}>
          Voucher
        </Link>
        
        {/* <Link className="nav-link" to="/blog">
          Blog
        </Link> */}
      </div>
      <div className="hamburger" onClick={toggleMenu} onMouseOver={toggleMenu}> 
      <i className={isMenuOpen ? 'fas fa-times' : 'fas fa-bars'} />        
      </div>
    </nav>
  );
};

export default Navbar;
