import React from 'react'
import './menus.css'

const menus = (props) => {

    console.log(props)
    
  return (
    <>
    <div className='center image-container'> 

        <h2>{props.image.data}</h2> 
        <img src={props.image.image} className='responsive-image'/>
        </div>

    </>
  )
}

export default menus