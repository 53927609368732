import React from 'react'

const About = () => {
  return (
    <>

    
<div className='center'>

<h4>About Tribe</h4>

</div>
    </>



  )
}

export default About